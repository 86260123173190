import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ClientJS } from 'clientjs';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';
import { GLOBAL } from '../constants';
import { Store } from '@ngrx/store';
import { RootReducerState } from '../store/root-reducers';
import { LoginRequestAction } from '../store/auth/actions/auth-actions';
import { Nullable } from '../types';

@Injectable({
  providedIn: 'root',
})
export class PaceEquityUtils {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private cookieService: CookieService,
    private store: Store<RootReducerState>
  ) {}
  public isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  public getLoggedInUser() {
    if (this.isBrowser()) {
      return localStorage.getItem('paceEquityUser')
        ? JSON.parse(localStorage.getItem('paceEquityUser') as any)
        : '';
    }
  }

  public getPermissionAccess() {
    if (this.isBrowser()) {
      return localStorage.getItem('pacePermissionAccess')
        ? JSON.parse(localStorage.getItem('pacePermissionAccess') as any)
        : '';
    }
  }

  public getSubPermissionAccess() {
    if (this.isBrowser()) {
      return localStorage.getItem('paceSubPermissionAccess')
        ? JSON.parse(localStorage.getItem('paceSubPermissionAccess') as any)
        : '';
    }
  }

  public setLoggedInUser(loggedInUser: any) {
    if (this.isBrowser()) {
      // loggedInUser.fullName = this.getFullName(loggedInUser);
      localStorage.setItem('paceEquityUser', JSON.stringify(loggedInUser));
      this.store.dispatch(new LoginRequestAction(loggedInUser));

      const subPermissions = this.getAssignedSubPermissions(loggedInUser);
      const subPermissionAccess = this.getSubPermissions(subPermissions);
      this.setSubPermissionAccess(subPermissionAccess);
    }
  }

  public verifyBrowserId() {
    const browserIdExists = this.cookieService.check('browserId');
    if (!browserIdExists) this.setBrowserId();
    let browserId = this.getBrowserId();
    return browserId;
  }

  public setBrowserId() {
    if (this.isBrowser()) {
      // Create a new ClientJS object and Get the client's fingerprint id
      const client = new ClientJS();
      const browserId = client.getFingerprint();
      this.cookieService.set('browserId', browserId?.toString());
    }
  }

  public getBrowserId() {
    return this.cookieService.get('browserId');
  }

  public setPermissionAccess(permissionAccess: any) {
    if (this.isBrowser()) {
      return localStorage.setItem(
        'pacePermissionAccess',
        JSON.stringify(permissionAccess)
      );
    }
  }

  public setSubPermissionAccess(permissionAccess: any) {
    if (this.isBrowser()) {
      return localStorage.setItem(
        'paceSubPermissionAccess',
        JSON.stringify(permissionAccess)
      );
    }
  }

  public removeLoggedInUser() {
    if (this.isBrowser()) {
      localStorage.removeItem('paceEquityUser');
      localStorage.removeItem('pacePermissionAccess');
      localStorage.removeItem('paceSubPermissionAccess');
      localStorage.removeItem('permissions');
    }
  }

  public getFullName = (contact: any) => {
    if (contact) {
      const { firstName, lastName } = contact;
      return `${firstName ? firstName.trim() : ''}${lastName ? ' ' : ''}${
        lastName ? lastName.trim() : ''
      }`;
    }
    return '';
  };

  getAssignedSubPermissions = (user: any) => {
    const loggedInUser = user ? user : this.getLoggedInUser();
    const availableSubPermissions = loggedInUser.permission.filter(
      (x: any) => x.permission && x.permission.parentId && x.accessType
    );
    return availableSubPermissions;
  };

  getSubPermissions = (subPermissionAccess: any) => {
    let subPermissions: any = {};
    subPermissionAccess.forEach((subPermissionAccess: any) => {
      const { id, accessType, permission } = subPermissionAccess;
      const { name, parentId, code } = permission;
      subPermissions[code] = {
        id,
        accessType,
        name,
        parentId,
        code,
      };
    });
    return subPermissions;
  };

  public getProjectCodeName(project: any) {
    return project
      ? project.code
        ? `${project.code} ${project.name}`
        : project.name
      : '';
  }
  public getProgramCodeName(program: any) {
    return program ? `${program.programCode} ${program.name}` : '';
  }

  public getSortOrder = (reverse: boolean) => {
    return reverse ? 'DESC' : 'ASC';
  };

  public checkIfValidDate(date: any, format: string, required: boolean) {
    if (date == null || (typeof date == 'string' && date.trim() == ''))
      return !required;
    return moment(date, format, required).isValid();
  }

  public checkIfValidDateOfMonthAndDay(
    date: any,
    format: string,
    required: boolean
  ) {
    if (date == null || (typeof date == 'string' && date.trim() == ''))
      return !required;
    const parsedDate = moment(date, format, true);
    return parsedDate.isValid() && parsedDate.format(format).length === 5;
  }

  public filterRequiredSubPhase(projectPhaseList: any[] = []) {
    return projectPhaseList.map((x: any) => ({ label: x.name, value: x.id }));
  }
  filterRequiredProformaPhase = (proformaPhases: any[] = []) => {
    const proformaPhaseData: {
      label: string;
      value: number;
      status: string;
    }[] = [];
    proformaPhases.forEach((x) => {
      proformaPhaseData.push({ label: x.name, value: x.id, status: x.status });
    });
    return proformaPhaseData;
  };

  checkPercentageInput(e: any) {
    let value = e.target.value;
    let idx = value.indexOf('.');
    if (!e.target.validity.valid) {
      if (idx < 3) {
        if (value.length - idx > 4) {
          if (e.target.valueAsNumber < 100) {
            e.target.value = value.slice(0, idx + 4);
          } else {
            e.target.value = value.slice(0, idx + 3);
          }
        } else {
          e.target.value = value.substring(0, value.length - 1);
        }
      } else {
        e.target.value = null;
      }
      return false;
    }
    if (idx >= 0 && value.length - idx > 4) {
      e.target.value = value.substring(0, value.length - 1);
      return false;
    }
    if (idx == -1 && parseFloat(value) > 100) {
      e.target.value = value.slice(0, 2);
    }
    return true;
  }

  checkPercentage(event: any) {
    if (event) {
      let value = event.target.value;
      value = value.replace(/[^0-9.]/g, '');
      const parts = value.split('.');
      if (parts.length > 2) {
        value = parts[0] + '.' + parts[1];
      }
      if (parts[1] && parts[1].length > 2) {
        value = parts[0] + '.' + parts[1].slice(0, 2);
      }
      if (Number(value) > 100) {
        value = value.substring(0, 2) + (parts[1] ? '.' + parts[1] : '');
      }
      event.target.value = value;
      return event.target.value;
    }
  }

  limitCharacters = (value: any, length: number) => {
    return value?.substring(0, length) + (value?.length > length ? '...' : '');
  };

  mapToModel = (from: any, to: any) => {
    Object.keys(from)
      .filter((key) => key in to)
      .forEach((key) => {
        if (
          from[key] &&
          !this.isArray(from[key]) &&
          !(from[key] instanceof Date) &&
          this.isObject(from[key])
        ) {
          to[key] = this.mapToModel(from[key], to[key]);
        } else {
          to[key] = from[key];
        }
      });
    return to;
  };

  mapClosingAdminFeeModel = (from: any, to: any) => {
    if (to == null && from != null) {
      to = from;
    }
    Object.keys(from)
      .filter((key) => key in to)
      .forEach((key) => {
        if (from[key] && !this.isArray(from[key]) && this.isObject(from[key])) {
          to[key] = this.mapClosingAdminFeeModel(from[key], to[key]);
        } else {
          to[key] = from[key];
        }
      });
    return to;
  };
  public getDefaultProps() {
    return {
      page: 1,
      limit: 10,
      totalCount: 0,
      loadingOverlay: false,
    };
  }

  public getDynamicPropertyValue(obj: any, dataToRetrieve: any) {
    return dataToRetrieve
      .split('.') // split string based on `.`
      .reduce(function (o: any, k: any) {
        return o && o[k]; // get inner property if `o` is defined else get `o` and return
      }, obj); // set initial value as object
  }

  public getTotal = (arr = [], prop?: any) => {
    return arr.reduce((acc, item) => {
      return (
        acc +
        Number((prop ? this.getDynamicPropertyValue(item, prop) : item) ?? 0)
      );
    }, 0);
  };

  getValidDate = (first: any, second: any, year: any) => {
    if (!first) return null;
    else if (!second) return first + '/' + (parseInt(year) + 1);
    else
      return (
        second +
        '/' +
        (new Date(first) > new Date(second)
          ? parseInt(year) + 1
          : year
        ).toString()
      );
  };

  public unmask(val: any): number | null {
    const value =
      typeof val === 'string' ? val.replace('$', '').replace(/,/g, '') : val;
    return value ? parseFloat(value) : null;
  }

  shallowCopy(data: any) {
    return JSON.parse(JSON.stringify(data));
  }

  public isObject(variable: any) {
    return typeof variable === 'object';
  }

  public isArray(obj: any) {
    return Array.isArray(obj);
  }
  public isArrayOfObject(obj: any) {
    return (
      this.isArray(obj) && obj.every((item: any) => typeof item === 'object')
    );
  }

  public parseJsonStringify(jsonData: any) {
    return JSON.parse(JSON.stringify(jsonData));
  }
  public parseJson(value: string) {
    return JSON.parse(value);
  }

  public isJsonParsable(string: string) {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  }

  public encodeToRouteParam = (str: string) => {
    return str
      ? str.replace('&', 'and').split(' ').join('-').toLowerCase()
      : '';
  };

  public decodeRouteParam = (str: string) => {
    return str
      ? str.replace('and', '&').split('-').join(' ').toLowerCase()
      : '';
  };

  maxValue(e: any, length: number) {
    let value = e.target.value;
    if (value.length > length) {
      e.target.value = value.slice(0, length - 1);
      return true;
    }
    return false;
  }

  public capitalizeFirstLetter = (string: any) => {
    let formattedString = `${string
      .replace(/([A-Z](?=[a-z]+)|[A-Z]+(?![a-z]))/g, ' $1')
      .replace(/^(.)|\s+(.)/g, (c: string) => c.toUpperCase())
      .trim()}`;
    formattedString = formattedString
      .split(' ')
      .map((x) =>
        x && GLOBAL.LOWERCASE_PREPOSITIONS.includes(x.toLowerCase())
          ? x.toLowerCase()
          : x
      )
      .join(' ');
    return formattedString;
  };

  /**
   * The function checks if a project's proforma is editable based on whether it has been committed or not.
   */
  public isProformaEditable = (project: any) =>
    project?.isProformaCommitted != null && !project.isProformaCommitted;

  /**
   * The function takes a string or Date object and returns a Date object.
   * @param {string | Date} date - The `date` parameter is a string or a Date object.
   */
  public parseDate(date: string | Date) {
    return date ? (date instanceof Date ? date : new Date(date)) : null;
  }

  getPaceEquityBase64Image = () =>
    this.getBase64Image('assets/img/pace-equity-high.jpg');

  async getBase64Image(url: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.crossOrigin = 'anonymous';

      img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };

      img.onerror = function (error) {
        reject(error);
      };
    });
  }

  async fileToBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result && typeof reader.result === "string") {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        } else {
          reject(new Error("Failed to convert file to Base64."));
        }
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  public getProjectSummaryTerms(
    programCode: any,
    hasBrokerFee: boolean = false
  ) {
    const data = new Map()

      .set('directCosts', 'Direct Construction Cost')
      .set('paceProduct', 'Pricing Scenario')
      .set('prepayment', 'PACE Equity Prepayment')
      .set('terms', 'Amortization Term')
      .set('financingTerms', 'Financing Term')
      .set('paceAnnualAssessment', 'Annual Tax Assessment')
      .set('firstPaymentDate', 'First Payment Date')
      .set('assetRate', 'Interest Rate')

      .set('projectDevelopmentCost', 'Project Development')

      .set(
        'legalClosingExpenses',
        hasBrokerFee
          ? 'Legal, Broker and Closing Expenses'
          : 'Legal and Closing Expenses'
      )
      .set('financeFacilityCost', 'Lender Origination Fee')
      .set('programAdminCosts', `${programCode}` + ' Fee')
      .set('capitalizedInterest', 'Capitalized Interest')
      .set('totalAssessmentAmountFinanced', 'Total Amount Financed')
      .set('benchmarkRate', 'Bench Mark Rate');
    return data;
  }

  public getCutomerAmortizationTerms() {
    const data = new Map()
      .set('customerName', 'Customer Name')
      .set('directCosts', 'Direct Construction Cost')
      .set('fees', 'Fees: Proj Dev, Legal & Financing Fees')
      .set('programAdminCosts', 'Program Admin Costs')
      .set('capitalizedInterest', 'Capitalized Interest ')
      .set('totalAssessmentAmountFinanced', 'Total Amount Financed')
      .set('terms', 'Amortization Term (years)')
      .set('financingTerms', 'Financing Term (years)')
      .set('paymentsPerYear', 'Payments per year')
      .set('numberOfPayments', 'Number of Payments ')
      .set('assetRate', 'Asset Rate')
      .set('daysPerYear', 'Days per year')
      .set('closeDate', 'Close Date')
      .set('amortizationStartDate', 'Amortization Start Date')
      .set('firstPaymentDate', 'First Payment Date')
      .set('daysOfAccuredInterest', 'Days of Accrued Interest (cap i)')
      .set('paceAnnualAssessment', 'PACE Annual Assessment (avg) ')
      .set('prepayment', 'Prepayment')
      .set('paceProgramCode', 'PACE Program Code')
      .set(
        'note',
        'Note: Amortization table is based on secured receipt dates, not statutory payment date'
      );
    return data;
  }

  public getPortfolioCfHeaderKeys(dataList: any) {
    let items = ['investment', 'interest', 'principal'];
    const data = [
      { key: 'date', value: '' },
      { key: 'totalInvestment', value: 'Investment' },
      { key: 'totalInterest', value: 'Interest' },
      { key: 'totalPrincipal', value: 'Principal' },
    ];
    dataList.forEach((element: any) => {
      items.forEach((x) => {
        data.push({
          key: x + '_' + element.projectName,
          value: element.projectName,
        });
      });
    });
    return data;
  }

  public getPortfolioCfHeaderValues(dataList: any) {
    let items = ['Investment', 'Interest', 'Principal'];
    const data = ['', 'Investment', 'Interest', 'Principal'];
    dataList.forEach((element: any) => {
      items.forEach((x) => {
        data.push(x);
      });
    });
    return data;
  }
  public getPortfolioCfTopHeaderValues(dataList: any, type?: string) {
    let items = ['Investment', 'Interest', 'Principal'];
    const data =
      type == 'projectName'
        ? ['', 'Total from Assets', '', '']
        : ['', '', '', ''];
    if (type == 'projectName') {
      dataList.forEach((element: any) => {
        items.forEach((x, i) => {
          i == 0 ? data.push(element.projectName) : data.push('');
        });
      });
    } else if (type == 'portfolioName') {
      dataList.forEach((element: any) => {
        items.forEach((x, i) => {
          i == 0 ? data.push(element.portfolioName) : data.push('');
        });
      });
    }
    return data;
  }

  public getExcelColumnName(columnNumber: number) {
    let dividend = columnNumber;
    let columnName = '';
    let modulo: number;

    while (dividend >= 1) {
      modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = +((dividend - modulo) / 26);
    }
    return columnName;
  }

  public getPeqCfHeaderKeys(dataList: any) {
    let items = ['pmt', 'servicingFee', 'adminFee', 'peqCharge'];
    const data = [
      { key: 'date', value: '' },
      { key: 'totalServicingFee', value: 'Servicing Fee' },
      { key: 'totalAdminFee', value: 'Admin Fee' },
      { key: 'totalPEQCharge', value: 'Total PEQ' },
    ];
    dataList.forEach((element: any) => {
      items.forEach((x) => {
        data.push({ key: x + '_' + element.id, value: element.id });
      });
    });
    return data;
  }
  public getPeqCfHeaderValues(dataList: any) {
    let items = ['Pmt', 'Servicing Fee', 'Admin Fee', 'Total PEQ Charge'];

    const data = ['', 'Servicing Fee', 'Admin Fee', 'Total PEQ'];
    dataList.forEach((element: any) => {
      items.forEach((x) => {
        data.push(x);
      });
    });
    return data;
  }
  public getPeqCfTopHeaderValues(dataList: any, type?: string) {
    let items = ['Pmt', 'Servicing Fee', 'Admin Fee', 'Total PEQ Charge'];

    const data =
      type == 'projectName'
        ? ['', 'Total from Assets', '', '']
        : ['', '', '', ''];
    if (type == 'projectName') {
      dataList.forEach((element: any) => {
        items.forEach((x, i) => {
          i == 0 ? data.push(element.projectName) : data.push('');
        });
      });
    } else if (type == 'portfolioName') {
      dataList.forEach((element: any) => {
        items.forEach((x, i) => {
          i == 0
            ? data.push(element.portfolioName + ' / ' + element.subphaseName)
            : data.push('');
        });
      });
    }
    return data;
  }

  public getAmortizationScheduleReportHeaders(code: any) {
    const data = new Map()
      .set('securedPaymentDate', 'Secured Receipt Date')
      .set('pmt', 'Pmt #')
      .set('beginBalance', 'Begin Balance')
      .set('interestPayment', 'Interest Payment')
      .set('principalPayment', 'Principal Payment')
      .set('totalPIPayment', 'Total P&I Pmt')
      .set('endingBalance', 'Ending Balance')
      .set('pehInterest', `${code} Interest`)
      .set('peServicing', 'PACE Equity Servicing')
      .set('adminInterest', 'Admin Interest');
    return data;
  }

  public getProjectSummaryHeaders() {
    const data = new Map()
      .set('input1', '')
      .set('value1', '')
      .set('', '')
      .set('input2', '')
      .set('value2', '');
    return data;
  }

  public getCustomerAmortizationHeaders() {
    const data = new Map()
      .set('input', '')
      .set('value', '')
      .set('', '')
      .set('pmt', 'Period')
      .set('clientAssessmentDueDate', 'Statutory Payment Date')
      .set('year', 'Year')
      .set('beginBalance', 'Begin Balance')
      .set('principalPayment', 'Principal')
      .set('interestPayment', 'Interest')
      .set('piPayment', 'P & I Payment')
      .set('programAdministrationFees', 'Program Administrator')
      .set('peServicing', 'PE Servicing')
      .set('totalPayment', 'Total Assessment')
      .set('securedReceiptDate', 'Secured Receipt Date');
    return data;
  }

  public getAssetReportHeaders(code: any) {
    const data = new Map()
      .set('inputs', '')
      .set('value1', '')
      .set('value2', '')
      .set('empty1', '')
      .set('securedPaymentDate', 'Secured Receipt Date')
      .set('pmt', 'Pmt #')
      .set('beginBalance', 'Begin Balance')
      .set('interestPayment', 'Interest Payment')
      .set('principalPayment', 'Principal Payment')
      .set('totalPIPayment', 'Total P&I Payment')
      .set('endingBalance', 'Ending Balance')
      .set('empty2', '')
      .set('pehInterest', `${code ?? ''} Interest`)
      .set('peServicing', 'PACE Equity Servicing')
      .set('adminInterest', 'Admin Interest')
      .set('empty3', '')
      .set('assessmentDueDateClient', 'Assessment Due Date')
      .set('pmtClient', 'Pmt #')
      .set('beginBalanceClient', 'Begin Balance')
      .set('interestPaymentClient', 'Interest Payment')
      .set('principalPaymentClient', 'Principal Payment')
      .set('programAdministrationFeesClient', 'Program Administration Fees')
      .set('peServicingClient', 'PEQ Admin Fee')
      .set('totalPaymentClient', 'Total Payment');
    return data;
  }

  public formatBooleanToYesOrNo(value: any, type: string) {
    switch (type) {
      case 'YES_NO':
        return value ? 'Yes' : 'No';

      case 'ACTIVE_INACTIVE':
        return value ? 'Active' : 'Inactive';

      default:
        return '';
    }
  }

  public compareString(check = '', against = '') {
    return check && against && check.toLowerCase() === against.toLowerCase();
  }

  getStorage(key: string, type: string = 'local') {
    if (type === 'local') {
      return localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key) as any)
        : null;
    } else if (type === 'session') {
      return sessionStorage.getItem(key)
        ? JSON.parse(sessionStorage.getItem(key) as any)
        : null;
    }
  }

  setStorage(key: string, value: any, type: string = 'local') {
    if (type === 'local') {
      localStorage.setItem(key, JSON.stringify(value));
    } else if (type === 'session') {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  removeStorage(key: string, type: string = 'local') {
    if (type === 'local') {
      localStorage.removeItem(key);
    } else if (type === 'session') {
      sessionStorage.removeItem(key);
    }
  }

  // radix -> 1 -> 1, 2 -> 10, 3 -> 1000, 4 -> 1000
  roundOffToNDigit = (number: number, radix: number = 1) => {
    return Math.round(number / radix) * radix;
  };

  // radix -> 1 -> 1, 2 -> 10, 3 -> 1000, 4 -> 1000
  roundUpToNDigit = (number: number, radix: number = 1) => {
    return Math.ceil(number / radix) * radix;
  };

  checkEmptyString = (value: any) => {
    return typeof value == 'string' && value == '' ? null : value;
  };

  mapLabelValue(arrayList: any[]) {
    if (arrayList?.length > 0) {
      arrayList = arrayList.map((x) => {
        return {
          ...x,
          label: x.name,
          value: x.id,
        };
      });
      return arrayList;
    } else {
      return [];
    }
  }

  downloadDocument(
    arrayBuffer: ArrayBuffer,
    type: string = 'pdf',
    initial: string = 'pe_commitment_'
  ) {
    const blob = this.convertArrayBufferToBlob(arrayBuffer, type);
    const fileName = initial + new Date().getTime();
    this.forceDownload(blob, fileName);
  }

  public convertArrayBufferToBlob(
    arrayBuffer: ArrayBuffer,
    type: string = 'pdf'
  ) {
    // Step 1: Convert the Array Buffer to a Uint8Array
    const typedArray = new Uint8Array(arrayBuffer);

    let documentType = 'application/pdf';
    switch (type) {
      case 'pdf':
        documentType = 'application/pdf';
        break;
      case 'doc':
        documentType =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      default:
        break;
    }
    // Step 2: Create a Blob from the typed array
    const blob = new Blob([typedArray], {
      type: documentType,
    });
    return blob;
  }

  forceDownload(blob: Blob, filename: string) {
    const href = window.URL.createObjectURL(blob);
    // window.open(href, '_blank');
    const anchor = document.createElement('a');
    Object.assign(anchor, { download: filename, href });
    anchor.click();
  }

  programListHandle(
    programId: Nullable<number>,
    role: string,
    programList: any,
    id: Nullable<number> // assessment id or project id
  ) {
    if (role.toLowerCase() == 'market leader') {
      if (!id) {
        programList = this.filterPeqEligibleProgram(programList);
      } else {
        const programFound = programList.find(
          (program: any) => program.value == programId
        );

        if (programFound && programFound?.peqEligible) {
          programList = this.filterPeqEligibleProgram(programList);
        } else if (programFound && !programFound?.peqEligible) {
          programList = this.filterPeqEligibleProgram(programList);
          programList.push(programFound);
        }
      }
    }
    return programList;
  }

  filterPeqEligibleProgram(programs: any) {
    return programs.filter((program: any) => program.peqEligible);
  }

  sort(array: any[] = [], sortBy: string = 'label', type: string = 'asc') {
    return array.sort((a, b) => {
      if (a?.[sortBy] < b?.[sortBy]) {
        return -1;
      }
      if (a?.[sortBy] > b?.[sortBy]) {
        return 1;
      }
      return 0;
    });
  }

  
  public convertToPlain(rtf:any) {
    const strippedHtml = rtf.replace(/<[^>]+>/g, '');
    return strippedHtml;
  }


  removeHtmlTags(input: any) {
    const doc = new DOMParser().parseFromString(input, 'text/html');
  
  // Convert HTML structure to text, handling entities and special characters
  let text = doc.body.textContent || doc.body.innerText;
  
  // Replace HTML elements with appropriate formatting
  text = text
    .replace(/<\/p>/gi, '\r\n') // Ensure each closing </p> creates a new line
    .replace(/<br\s*\/?>/gi, '\r\n') // Convert <br> to new lines
    .trim();

  return text;
  }

  getFirstPaymentYearList() {
    const currentYear = moment().year();
   return Array.from({ length: 4 }, (_, i) => {
      const year = currentYear + i;
      return { label: year, value: year };
    });
  }
  
}

export const APICONSTANTS = {
  PORTFOLIO: '/portfolios',
  PROGRAM: '/programs',
  ROLE: '/roles',
  COMPANY: '/companies',
  PROJECT: '/projects',
  ASSET: '/assets',
  ASSETDATES: '/assetDates',
  LOGOUT: '/logout',
  REFRESH_TOKEN: '/refresh',
  ASSET_PROPERTY: '/assets/properties',
  ASSET_CREDITMETRICS: '/credit-metrics',
  ASSET_CAPITAL_STACK: '/assets/capital-stacks',
  ASSET_SERVICING_AND_OTHERS: '/assets/servicing-and-others',
  ASSET_IMPACT_DATA: '/impacts',
  AUTH: '/auth',
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  AUTH_USER: '/auth-user',
  PERMISSION: '/permissions',
  USER: '/users',
  USER_PERMISSION: '/users/permissions',
  SEND_INVITATION: '/send-invitation',
  SET_PASSWORD: '/set-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_CODE: '/verify-code',
  VERIFY_MFA_CODE: '/verify-mfa-code',
  RESEND_MFA_CODE: '/resend-mfa-code',
  CHANGE_PASSWORD: '/change-password',
  REPORT: '/report',
  DASHBOARD: '/dashboard',
  AMORTIZE: '/amortize',
  DOCUMENT: '/documents',
  DOCUMENT_DOWNLOAD: '/documents/download',
  PE_COMMITMENT: '/pe-commitment',
  DOCUMENT_SHARE: '/documents/share-access',
  DOCUMENT_SEARCH: '/documents/search',
  DOCUMENT_MOVE: '/documents/move',
  PAYMENT: '/payments',
  PHASE: '/phases',
  DASHBOARD_PHASE: '/phase-dashboard',
  CASE: '/cases',
  NOTE_ATTACHMENT: '/note-attachments',
  LINK_FOLDER: '/link-folder',
  GENERATE_TEMPLATE: '/generate-template',
  SUMMARY_REPORT: '/summary-report',
  DASHBOARD_LAYOUT: '/dashboard-layouts',
  AMO_END_BALANCE: 'amo-end-balance',
  CONTACT: '/contacts',
  LENDER_DATABASE: '/lenders',
  STATE_DATABASE: '/states',
  LENDER_DATABASE_CONTACTS: '/lenders/contacts',
  LOOKUP: '/lookup',
  ASSESSMENTS: '/assessments',
  PROPERTY: '/property',
  ECM:'/ecm',
  VERIFY_STATE:'/verify-state',
  PRICING_ADJUSTMENT_DRIVERS: '/pricing-adjustment-drivers',
  SETTINGS:'/settings',
  PE_TERM_SHEET: '/pe-term-sheet',
  CLOSING_GROSS_MARGIN: '/closing-gross-margin',
  SECURITIZATION_BONUS: '/securitization-bonus'
};

import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpClientService
  ) { }

  fetchClosingGrossMarginRange(body: any) {
    return this.http
      .post(`${APICONSTANTS.SETTINGS}${APICONSTANTS.CLOSING_GROSS_MARGIN}`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchSecuritizationBonusRange(body: any) {
    return this.http
      .post(`${APICONSTANTS.SETTINGS}${APICONSTANTS.SECURITIZATION_BONUS}`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}

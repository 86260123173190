export const dashboardRichtextConstants = [
  'program_defaultAndForeclosure',
  'program_billingAndCollectionDefinition',
  'program_flowOfFunds',
  'program_sirDefinition',
  'program_termDefinition',
  'program_notes',
  'program_servicingDescription',
  'program_programContacts',
  'program_programApprovals',
  'asset_servicing_servicingApprovalNotes',
  'asset_servicing_disbursementApprovalNotes',
];

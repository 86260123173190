import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { MESSAGE_CONSTANTS } from '../constants';
import { ToasterService } from '../services';
import { PaceEquityUtils } from '../utils';

@Injectable()
export class ServerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router, 
    private toasterService: ToasterService,
    private paceEquityUtil: PaceEquityUtils
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request.clone()).pipe(
      catchError((error: HttpErrorResponse) => {
        // if (error.error.message) {
        //   this.toasterService.error(error.error.message);
        //   return throwError(error);
        // } else
        if (error.status === 400) {
          this.toasterService.error(
            error.error?.data?.message || MESSAGE_CONSTANTS.BAD_REQUEST
          );
          return throwError(() => error);
        }
        if (error.status === 403) {
          this.toasterService.error(
            error.error?.data?.message || MESSAGE_CONSTANTS.FORBIDDEN
          );
          return throwError(() => error);
        } 
        if (error.status === 429) {
          this.toasterService.warning(error?.error?.message);
          return throwError(() => error);
        } 
        if (error.status === 500) {
          this.toasterService.error(
            error.error?.data?.message || MESSAGE_CONSTANTS.SERVER_ERROR
          );
          return throwError(() => error);
        }
        // this.toasterService.error(error.error.message);
        return throwError(() => error);
      })
    );
  }
}

import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbs = signal<{ name: string; path: string | null }[]>([]);

  constructor() {}

  updateBreadCrumb(breadCrumb: { name: string; path: string | null }[]) {
    this.breadcrumbs.set(breadCrumb);
  }
}

import Quill from 'quill';
import { environment } from './../../environments/environment';
import { APICONSTANTS } from './api.constant';
import { DASHBOARD_CONSTANTS } from './dashboard.constant';
import { QuillConfigModule } from 'ngx-quill';
const API_BASE_URL = environment.apiUrl;

export const GLOBAL = {
  TINYMCE_API: environment.tinymceApiKey,
  MONTH: [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ],
  // projectTypeList: [
  //   { id: 1, value: 'New Construction', label: 'New Construction' },
  //   { id: 2, value: 'Retrofit', label: 'Retrofit' },
  //   { id: 3, value: 'Solar', label: 'Solar' },
  //   { id: 4, value: 'Retroactive', label: 'Retroactive' },
  //   { id: 5, value: 'Major Renovation', label: 'Major Renovation' },
  //   { id: 6, value: 'Mid-Construction', label: 'Mid-Construction', fromSalesforce: true },
  //   { id: 7, value: 'Redevelopment', label: 'Redevelopment', fromSalesforce: true },
  //   { id: 8, value: 'Renovation', label: 'Renovation', fromSalesforce: true },
  //   { id: 9, value: 'Refinance', label: 'Refinance', fromSalesforce: true },
  //   { id: 10, value: 'Solar/Renewables', label: 'Solar/Renewables', fromSalesforce: true },
  //   { id: 11, value: 'Expansion', label: 'Expansion', fromSalesforce: true },
  //   { id: 12, value: 'Other', label: 'Other', fromSalesforce: true },
  // ],
  projectTypeList: [
    { id: 1, value: 'New Construction', label: 'New Construction' },
    // {id: 2, value: 'Retrofit', label: 'Retrofit'},
    // {id: 3, value: 'Solar', label: 'Solar'},
    // {id: 4, value: 'Retroactive', label: 'Retroactive'},
    // {id: 5, value: 'Major Renovation', label: 'Major Renovation'},
    { id: 6, value: 'Mid-Construction', label: 'Mid-Construction', fromSalesforce: true },
    { id: 7, value: 'Redevelopment', label: 'Redevelopment', fromSalesforce: true },
    { id: 8, value: 'Renovation', label: 'Renovation', fromSalesforce: true },
    { id: 9, value: 'Refinance', label: 'Refinance', fromSalesforce: true },
    { id: 10, value: 'Solar/Renewables', label: 'Solar/Renewables', fromSalesforce: true },
    { id: 11, value: 'Expansion', label: 'Expansion', fromSalesforce: true },
    { id: 12, value: 'Other', label: 'Other', fromSalesforce: true },
  ],
  propertyTypeList: [
    { id: 1, value: 'Retail', label: 'Retail' },
    { id: 2, value: 'Office', label: 'Office' },
    { id: 3, value: 'Multifamily', label: 'Multifamily' },
    { id: 4, value: 'Hotel', label: 'Hotel' },
    { id: 5, value: 'Assisted Living', label: 'Assisted Living' },
    {
      id: 6,
      value: 'Agriculture & Dairy facilities/Farms',
      label: 'Agriculture & Dairy facilities/Farms',
    },
    { id: 7, value: 'Hospitals', label: 'Hospitals' },
    { id: 8, value: 'Industrial', label: 'Industrial' },
    { id: 9, value: 'Non-profit', label: 'Non-profit' },
    { id: 10, value: 'Schools', label: 'Schools' },
    { id: 11, value: 'Universities', label: 'Universities' },
    {
      id: 12,
      value: 'Special Purpose / Other',
      label: 'Special Purpose / Other',
    },
  ],
  eeStatusList: [
    { id: 1, value: '00 Not Started', label: '00 Not Started' },
    { id: 2, value: '01 Data Review', label: '01 Data Review' },
    {
      id: 3,
      value: '02 Engineering in Process',
      label: '02 Engineering in Process',
    },
    { id: 4, value: '03 Preparing Report', label: '03 Preparing Report' },
    { id: 5, value: '04 Report Submitted', label: '04 Report Submitted' },
    { id: 6, value: '05 Complete', label: '05 Complete' },
  ],
  cirrusStatusList: [
    { id: 1, value: 'Not applicable', label: '01. Not applicable' },
    { id: 1, value: 'Not started', label: '02. Not started' },
    {
      id: 2,
      value: 'Engineering in process',
      label: '03. Engineering in process',
    },
    {
      id: 3,
      value: 'Preparing Performance Report',
      label: '04. Preparing Performance Report',
    },
    {
      id: 4,
      value: 'Report presented – declined',
      label: '05. Report presented – declined',
    },
    {
      id: 5,
      value: 'Design changes in process (client)',
      label: '06. Design changes in process (client)',
    },
    {
      id: 6,
      value: 'Design changes confirmed',
      label: '07. Design changes confirmed',
    },
    {
      id: 7,
      value: 'Submitted to NBI for verification',
      label: '08. Submitted to NBI for verification',
    },
    {
      id: 8,
      value: 'CIRRUS Low Carbon Verified',
      label: '09. CIRRUS Low Carbon Verified',
    },
    {
      id: 9,
      value: 'Changes/Final drawings being reviewed',
      label: '10. Changes/Final drawings being reviewed',
    },
    {
      id: 10,
      value: 'CIRRUS Low Carbon confirmed at CO',
      label: '11. CIRRUS Low Carbon confirmed at CO',
    },
  ],
  cirrusInterestList: [
    { id: 1, value: 'Committed to CIRRUS', label: '01. Committed to CIRRUS' },
    { id: 2, value: 'High interest', label: '02. High interest' },
    { id: 3, value: 'Solar/renewables', label: '03. Solar/renewables' },
    {
      id: 4,
      value: 'Low interest/No interest',
      label: '04. Low interest/No interest',
    },
    // {id: 5, value: 'E Not Interested', label: 'Low interest/No interest '},
    {
      id: 5,
      value: 'Not applicable – retroactive',
      label: '05. Not applicable – retroactive',
    },
    {
      id: 6,
      value: 'Not applicable – too large',
      label: '06. Not applicable – too large',
    },
    {
      id: 7,
      value: 'Not applicable – design complete/timing',
      label: '07. Not applicable – design complete/timing',
    },
    {
      id: 8,
      value: 'Not applicable- other',
      label: '08. Not applicable- other',
    },
    { id: 9, value: 'Not Sure', label: '09. Not Sure' },
  ],
  prePostClosinglist: [10, 11, 12, 19],
  postPostClosinglist: [
    // 9, 13, 14, 15, 16, 17
    14,
    15,
    16,
    17, //subPhaseId after post closing excluding terminated and stalled
  ],
  stateList: [
    {
      label: 'Alabama',
      value: 'AL',
    },
    {
      label: 'Alaska',
      value: 'AK',
    },
    {
      label: 'American Samoa',
      value: 'AS',
    },
    {
      label: 'Arizona',
      value: 'AZ',
    },
    {
      label: 'Arkansas',
      value: 'AR',
    },
    {
      label: 'California',
      value: 'CA',
    },
    {
      label: 'Colorado',
      value: 'CO',
    },
    {
      label: 'Connecticut',
      value: 'CT',
    },
    {
      label: 'Delaware',
      value: 'DE',
    },
    {
      label: 'District Of Columbia',
      value: 'DC',
    },
    {
      label: 'Federated States Of Micronesia',
      value: 'FM',
    },
    {
      label: 'Florida',
      value: 'FL',
    },
    {
      label: 'Georgia',
      value: 'GA',
    },
    {
      label: 'Guam',
      value: 'GU',
    },
    {
      label: 'Hawaii',
      value: 'HI',
    },
    {
      label: 'Idaho',
      value: 'ID',
    },
    {
      label: 'Illinois',
      value: 'IL',
    },
    {
      label: 'Indiana',
      value: 'IN',
    },
    {
      label: 'Iowa',
      value: 'IA',
    },
    {
      label: 'Kansas',
      value: 'KS',
    },
    {
      label: 'Kentucky',
      value: 'KY',
    },
    {
      label: 'Louisiana',
      value: 'LA',
    },
    {
      label: 'Maine',
      value: 'ME',
    },
    {
      label: 'Marshall Islands',
      value: 'MH',
    },
    {
      label: 'Maryland',
      value: 'MD',
    },
    {
      label: 'Massachusetts',
      value: 'MA',
    },
    {
      label: 'Michigan',
      value: 'MI',
    },
    {
      label: 'Minnesota',
      value: 'MN',
    },
    {
      label: 'Mississippi',
      value: 'MS',
    },
    {
      label: 'Missouri',
      value: 'MO',
    },
    {
      label: 'Montana',
      value: 'MT',
    },
    {
      label: 'Nebraska',
      value: 'NE',
    },
    {
      label: 'Nevada',
      value: 'NV',
    },
    {
      label: 'New Hampshire',
      value: 'NH',
    },
    {
      label: 'New Jersey',
      value: 'NJ',
    },
    {
      label: 'New Mexico',
      value: 'NM',
    },
    {
      label: 'New York',
      value: 'NY',
    },
    {
      label: 'North Carolina',
      value: 'NC',
    },
    {
      label: 'North Dakota',
      value: 'ND',
    },
    {
      label: 'Northern Mariana Islands',
      value: 'MP',
    },
    {
      label: 'Ohio',
      value: 'OH',
    },
    {
      label: 'Oklahoma',
      value: 'OK',
    },
    {
      label: 'Oregon',
      value: 'OR',
    },
    {
      label: 'Palau',
      value: 'PW',
    },
    {
      label: 'Pennsylvania',
      value: 'PA',
    },
    {
      label: 'Puerto Rico',
      value: 'PR',
    },
    {
      label: 'Rhode Island',
      value: 'RI',
    },
    {
      label: 'South Carolina',
      value: 'SC',
    },
    {
      label: 'South Dakota',
      value: 'SD',
    },
    {
      label: 'Tennessee',
      value: 'TN',
    },
    {
      label: 'Texas',
      value: 'TX',
    },
    {
      label: 'Utah',
      value: 'UT',
    },
    {
      label: 'Vermont',
      value: 'VT',
    },
    {
      label: 'Virgin Islands',
      value: 'VI',
    },
    {
      label: 'Virginia',
      value: 'VA',
    },
    {
      label: 'Washington',
      value: 'WA',
    },
    {
      label: 'West Virginia',
      value: 'WV',
    },
    {
      label: 'Wisconsin',
      value: 'WI',
    },
    {
      label: 'Wyoming',
      value: 'WY',
    },
  ],
  modalConfig: {
    animation: true,
    backdrop: true,
    data: {
      title: '',
      body: '',
      formData: {},
      confirmButtonLabel: '',
    },
    ignoreBackdropClick: true,
    keyboard: true,
    modalClass: 'modal-lg modal-dialog-scrollable',
  },
  tinyMCESettings: {
    outputFormat: 'html',
    height: 300,
    readonly: 0,
    paste_data_images: true,
    entity_encoding: 'raw',
    plugins:
      'paste pagebreak print preview  searchreplace autolink directionality visualblocks visualchars fullscreen image code link media template codesample table charmap hr pagebreak nonbreaking anchor  insertdatetime advlist lists  wordcount  textpattern help',
    toolbar1:
      'insertdatetime pagebreak image code restoredraft formatselect fontselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
    insertdatetime_formats: ['%H:%M:%S', '%Y-%m-%d', '%I:%M:%S %p', '%D'],
    insertdatetime_element: false,
    browser_spellcheck: true,
    menubar: false,
    toolbar: false,
    default_link_target: '_blank',
    images_upload_handler: function (
      blobInfo: any,
      success: any,
      failure: any
    ) {
      var xhr: any, formData;
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      let data = JSON.parse(localStorage.getItem('paceEquityUser') as any);
      xhr.open(
        'POST',
        `${API_BASE_URL}${APICONSTANTS.CASE}${APICONSTANTS.NOTE_ATTACHMENT}`
      );
      xhr.setRequestHeader('Authorization', data.token);
      xhr.onload = function () {
        var json;
        if (xhr.status != 200) {
          failure('HTTP Error: ' + xhr.status);
          return;
        }
        json = JSON.parse(xhr.responseText);
        json = json.data;
        if (!json || typeof json.location != 'string') {
          failure('Invalid JSON: ' + xhr.responseText);
          return;
        }
        success(json.location);
      };
      formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      xhr.send(formData);
    },
  },
  ALLOW_PAYMENTS_IN_PHASE: [14, 16],
  VERIFICATION_DOCUMENT_STATUS: {
    STATUS: [
      { label: 'Waived', value: 'waived' },
      { label: 'Verified', value: 'verified' },
      { label: 'Pending', value: 'pending' },
    ],
    ALL: [
      { label: 'Waived All', value: 'waived' },
      { label: 'Verified All', value: 'verified' },
      { label: 'Pending All', value: 'pending' },
    ],
  },
  MODAL: {
    PROGRAM: 'program',
    PROJECT: 'project',
    USER: 'user',
    PORTFOLIO: 'portfolio',
    STATE: 'state_database',
    LENDER: 'lender_database',
  },
  OPERATION: {
    LIST: 'list',
    ADD: 'add',
    EDIT: 'edit',
    DETAIL: 'detail',
    OPTIONS: 'options',
    SPECIFIC: {
      PROJECT: 'project',
      PROGRAM_DASHBOARD: 'program-dashboard',
      PROGRAM_CONTACTS: 'program-contacts',
      PROGRAM_SERVICING: 'program-servicing',
      PROGRAM_FEES: 'program-fees',
      PORTFOLIO_TRANCHE: 'tranche',
      PORTFOLIO_SWEEP_REPORT: 'sweep-report',
      PORTFOLIO_DETAIL: 'portfolio-detail',
      FUNDING_TYPE: 'FUNDING_TYPE',
      CIRRUS_LOW_CARBON_SCOPE: 'CIRRUS_LOW_CARBON_SCOPE',
    },
  },
  LOWERCASE_PREPOSITIONS: ['the', 'or', 'and', 'to', 'in'],
  SUB_VERTICAL_PROPERTY_TYPE: [
    {
      id: 1,
      value: 'Independent Living Facility (ILF)',
      label: 'Independent Living Facility (ILF)',
    },
    {
      id: 2,
      value: 'Assisted Living Facility (ALF)',
      label: 'Assisted Living Facility (ALF)',
    },
    {
      id: 3,
      value: 'Continuing Care Retirement Facilities (CCRF)',
      label: 'Continuing Care Retirement Facilities (CCRF)',
    },
    {
      id: 4,
      value: 'Skilled Nursing Facility (SNF)',
      label: 'Skilled Nursing Facility (SNF)',
    },
    {
      id: 5,
      value: 'Memory Care Facility (MCF)',
      label: 'Memory Care Facility (MCF)',
    },
    {
      id: 6,
      value: 'Luxury Hospitality',
      label: 'Luxury Hospitality',
    },
    {
      id: 7,
      value: 'Full Service Hospitality',
      label: 'Full Service Hospitality',
    },
    {
      id: 8,
      value: 'Extended Stay Hospitality',
      label: 'Extended Stay Hospitality',
    },
    {
      id: 9,
      value: 'Select Service Hospitality',
      label: 'Select Service Hospitality',
    },
    {
      id: 10,
      value: 'Limited Service Hospitality',
      label: 'Limited Service Hospitality',
    },
    {
      id: 11,
      value: 'Office',
      label: 'Office',
    },
    {
      id: 12,
      value: 'Retail',
      label: 'Retail',
    },
    {
      id: 13,
      value: 'Multifamily',
      label: 'Multifamily',
    },
    {
      id: 14,
      value: 'Refrigerated/Cold Storage Facility',
      label: 'Refrigerated/Cold Storage Facility',
    },
    {
      id: 15,
      value: 'Manufacturing/Industrial',
      label: 'Manufacturing/Industrial',
    },
    {
      id: 16,
      value: 'Daycare Facility Warehouse/Distribution',
      label: 'Daycare Facility Warehouse/Distribution',
    },
    {
      id: 17,
      value: 'Condo',
      label: 'Condo',
    },
    {
      id: 18,
      value: 'Other',
      label: 'Other',
    },
    {
      id: 19,
      value: 'Anchor Tenant',
      label: 'Anchor Tenant',
    },
    {
      id: 20,
      value: 'Medical Office',
      label: 'Medical Office',
    },
    {
      id: 21,
      value: 'Self Storage',
      label: 'Self Storage',
    },
    {
      id: 22,
      value: 'Restaurant',
      label: 'Restaurant',
    },
  ],
  YES_NO_OPTIONS: [
    { label: '--- Select ---', value: null, selected: true },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],
  YES_NO_BOOLEAN_OPTIONS: [
    { label: 'Yes', value: true, selected: true },
    { label: 'No', value: false },
  ],
  HAS_SUBTABS: [
    'PROJECT DETAIL',
    'DISBURSEMENT',
    'PAYMENT DETAIL',
    'ASSESSMENTS',
  ],
  STATE_DATABASE_FILTER: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
  dateFormat: 'MM/dd/yyyy',
  myDatePickerOptions: {
    // Your options
    dateFormat: 'mm/dd/yyyy',
    showTodayBtn: false,
    showClearDateBtn: false,
    closeAfterSelect: true,
    maxYear: 2080,
    minYear: 2010,
  },
  IMPACT_DATA_VIEW: ['Energy Engineering', 'EE Test'],
  MARKET_LEADER: 'MARKET LEADER',

  ReportList: [
    { label: 'Amortizing Accrual Report', value: 'AMORTIZING_ACCRUAL' },
    // {
    //   label: 'Asset Report',
    //   value: 'ASSET_REPORT',
    // },
    // {
    //   label: 'Asset Report',
    //   value: 'ASSET_REPORT',
    // },
    // {
    //   label: 'Asset Report',
    //   value: 'ASSET_REPORT',
    // },
    {
      label: 'At Risk Report',
      value: 'HIGH_RISK',
    },
    {
      label: 'CapI Statement',
      value: 'CapI_Statement',
    },
    { label: 'Capitalized Interest Report', value: 'CAPITALIZED_INTEREST' },
    { label: 'Data Tape Report', value: 'DATA_TAPE' },
    { label: 'DBRS Report', value: 'DBRS' },
    { label: 'Delinquency Report', value: 'DELINQUENCY' },
    { label: 'Disbursement Open Balance Report', value: 'OPEN_BALANCE' },
    { label: 'PEQ Cashflow Report', value: 'PEQ_CASHFLOW' },
    { label: 'Portfolio Cashflow Report', value: 'PF_CASHFLOW' },
    { label: 'Prepayment Detail Report', value: 'PREPAYMENT_DETAIL' },
    { label: 'Prepayment Summary Report', value: 'PREPAYMENT_SUMMARY' },
    { label: 'Remittance Report', value: 'REMITTANCE' },
    { label: 'Trial Balance Report', value: 'TRIAL_BALANCE' },
  ],
  groupingDashboard: [
    {
      label: 'Phase',
      value: DASHBOARD_CONSTANTS.DEFAULT_GROUP_BY,
      disabled: false,
    },
    // {
    //   label: 'Portfolio',
    //   value: 'portfolio_name',
    //   disabled: true,
    // },
    {
      label: 'Market Leader',
      value: 'project_marketLeaderId',
      disabled: true,
    },
    {
      label: 'Transaction Manager',
      value: 'project_transactionManagerId',
      disabled: true,
    },
    {
      label: 'State',
      value: 'asset_property_state',
      disabled: true,
    },
    {
      label: 'Property Type',
      value: 'asset_property_propertyType',
      disabled: true,
    },
    {
      label: 'Project Type',
      value: 'asset_property_buildingType',
      disabled: true,
    },
    {
      label: 'PACE Product',
      value: 'asset_pricing_pricingScenario',
      disabled: true,
    },
  ],
  preferredLender: [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ],
  debounceTime: 300,

  energyBaseline: [
    {
      id: 1,
      value: 'IECC 2021 and/or ASHRAE 90.1 - 2019',
      label: 'IECC 2021 and/or ASHRAE 90.1 - 2019',
    },
    {
      id: 1,
      value: 'IECC 2018 and/or ASHRAE 90.1 2016',
      label: 'IECC 2018 and/or ASHRAE 90.1 2016',
    },
    {
      id: 1,
      value: 'IECC 2015 and/or ASHRAE 90.1 2013',
      label: 'IECC 2015 and/or ASHRAE 90.1 2013',
    },
    {
      id: 1,
      value: 'IECC 2012 and/or ASHRAE 90.1 2010 or earlier',
      label: 'IECC 2012 and/or ASHRAE 90.1 2010 or earlier',
    },
    { id: 5, value: 'Other', label: 'Other' },
  ],

  climateZone: [
    { id: 1, value: '1A', label: '1A' },
    { id: 1, value: '2A', label: '2A' },
    { id: 1, value: '2B', label: '2B' },
    { id: 1, value: '3A', label: '3A' },
    { id: 1, value: '3B', label: '3B' },
    { id: 1, value: '3C', label: '3C' },
    { id: 1, value: '4A', label: '4A' },
    { id: 1, value: '4B', label: '4B' },
    { id: 1, value: '4C - Marine', label: '4C - Marine' },
    { id: 1, value: '5A', label: '5A' },
    { id: 1, value: '5B', label: '5B' },
    { id: 1, value: '5C', label: '5C' },
    { id: 1, value: '6A', label: '6A' },
    { id: 1, value: '6B', label: '6B' },
    { id: 1, value: '7', label: '7' },
    { id: 1, value: '8', label: '8' },
  ],

  pricingAdjustment: [
    {
      name: 'TAF',
      value: '$10m+',
      standardRate: -10,
      cirrusRate: null,
      options: [],
    },
    {
      name: 'PACE LTV',
      value: '< 25%',
      standardRate: 0,
      cirrusRate: null,
      options: [],
    },
    {
      name: 'Property Type',
      value: 'Hotel - Flagged',
      standardRate: 15,
      cirrusRate: null,
      options: [],
    },
    {
      name: 'Prepayment',
      value: 'Years 1-5 3%, 6-10 2%, 11-15 1%',
      standardRate: 0,
      cirrusRate: null,
      options: [
        {
          label: 'Years 1-5 3%, 6-10 2%, 11-15 1%',
          value: 'Years 1-5 3%, 6-10 2%, 11-15 1%',
        },
      ],
    },
    {
      name: 'Lender Readiness',
      value: 'In play with multiple sources - close within 6 months',
      standardRate: 0,
      cirrusRate: null,
      options: [
        {
          label: 'Ready to close - consenting lender in place',
          value: 'Ready to close - consenting lender in place',
        },
        {
          label: 'Lender in place - not sure if they are consenting',
          value: 'Lender in place - not sure if they are consenting',
        },
        {
          label: 'In play with multiple sources - close within 6 months',
          value: 'In play with multiple sources - close within 6 months',
        },
        {
          label: 'No lender yet (early)',
          value: 'No lender yet (early)',
        },
      ],
    },
    {
      name: 'CIRRUS Interest',
      value: 'E - Not Applicable',
      standardRate: 0,
      cirrusRate: null,
      options: [
        {
          label: 'A - Not sure',
          value: 'A - Not sure',
        },
        {
          label: 'B - Commited to CIRRUS',
          value: 'B - Commited to CIRRUS',
        },
        {
          label: 'C - Evaluate High Interest',
          value: 'C - Evaluate High Interest',
        },
        {
          label: 'D - Evaluate Low Interest',
          value: 'D - Evaluate Low Interest',
        },
        {
          label: 'E - Not Applicable',
          value: 'E - Not Applicable',
        },
      ],
    },
    {
      name: 'Competition',
      value: 'Not Competitive or Not Sure',
      standardRate: -40,
      cirrusRate: null,
      options: [
        {
          label: 'Not Competitive or Not Sure',
          value: 'Not Competitive or Not Sure',
        },
      ],
    },
    {
      name: 'Esoteric',
      value: 'Not Esoteric',
      standardRate: 0,
      cirrusRate: null,
      options: [
        {
          label: 'Not Esoteric',
          value: 'Not Esoteric',
        },
        {
          label: 'Not Esoteric in High RUCA',
          value: 'Not Esoteric in High RUCA',
        },
        {
          label: 'Esoteric in low RUCA',
          value: 'Esoteric in low RUCA',
        },
        {
          label: 'Esoteric in High RUCA',
          value: 'Esoteric in High RUCA',
        },
      ],
    },
    {
      name: 'Building Code',
      value: 'IECC 2015 - Moderate difficulty',
      standardRate: null,
      cirrusRate: -60,
      options: [
        {
          label: 'IECC 2021 - Easy',
          value: 'IECC 2021 - Easy',
        },
        {
          label: 'IECC 2018 - Not that difficult',
          value: 'IECC 2018 - Not that difficult',
        },
        {
          label: 'IECC 2015 - Moderate difficulty',
          value: 'IECC 2015 - Moderate difficulty',
        },
        {
          label: 'IECC 2012+ - more difficult',
          value: 'IECC 2012+ - more difficult',
        },
      ],
    },
    {
      name: 'Manager Adjustment',
      value: '',
      standardRate: 0,
      cirrusRate: 0,
      options: [],
    },
  ],
  priceAdjustment: [
    'buildingCodeAdjustment',
    'cirrusInterestAdjustment',
    'competitionAdjustment',
    'esotericAdjustment',
    'lenderReadinessAdjustment',
    'managerAdjustment',
    'paceLtvAdjustment',
    'prepaymentAdjustment',
    'propertyTypeAdjustment',
    'tafAdjustment',
  ],

  businessDays: [
    { label: '10 business days', value: 10 },
    { label: '15 business days', value: 15 },
    { label: '20 business days', value: 20 },
  ],
  termCalculation: {
    EXCLUDE_CAP_I: 'Exclude Cap-I',
    INCLUDE_CAP_I: 'Include Cap-I',
  },

  projectPricingApproach: {
    FLOW_ADJUSTMENTS: 'Flow Adjustments to committed Rate',
    STEP_BY_STEP: 'Step by Step Pricing',
  },
  mdbEditorSettings: {
    styles: {
      hidden: false,
    },
    formatting: {
      hidden: true,
    },
    align: {
      hidden: true,
    },
    lists: {
      disabled: false,
      hidden: false,
      insertUnorderedList: { hidden: true },
    },
  },

  iopTerms: [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
    {
      label: 4,
      value: 4,
    },
    {
      label: 5,
      value: 5,
    },
  ],
  quillConfigOptions: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      // ['blockquote', 'code-block'],
      // ['link', 'image', 'video'],

      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction
      [{ color: [] }, { background: [] }],
      // [{ size: [false] }], // custom dropdown
      [{ header: [false] }],

      // [{ font: [] }],
      [{ align: [] }],
      // ['clean'],
    ],
  },
  customOptions: [
    {
      import: 'formats/font',
      whitelist: ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace'],
    },
  ],
  fonts: [
    { value: 'arial', text: 'Arial' },
    { value: 'georgia', text: 'Georgia' },
    { value: 'times-new-roman', text: 'Times New Roman' },
    { value: 'verdana', text: 'Verdana' },
    // Add more fonts as needed
  ],

  loanTerms: [
    { label: 'Minimum DSCR', value: 'Minimum DSCR' },
    { label: 'Maximum PLTV', value: 'Maximum PLTV' },
  ],

  noiList: [
    { label: 'Appraisal NOI', value: 'Appraisal NOI' },
    { label: 'Owner NOI', value: 'Owner NOI' },
  ],

  tabNameList: {
    DETAIL: 'DETAIL',
    SIZING_DASHBOARD: 'SIZING DASHBOARD',
    PROJECT_P_AND_L: 'PROJECT P & L',
    PROPERTY: 'PROPERTY',
    ECM: 'ECM',
    CAPITAL_STACK: 'CAPITAL STACK',
    CREDIT_METRICS: 'CREDIT METRICS',
    IMPACT_DATA: 'IMPACT DATA',
  },
  SECURITIZATION_BONUS_TYPE: [
    {
      label: 'Paid at Closing',
      value: 'Paid at Closing',
    },
    {
      label: '5 Installments',
      value: '5 Installments',
    },
  ]

};
